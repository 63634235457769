import { useLayoutEffect } from "react";
import { navigate } from "gatsby";

const Page404 = () => {
  useLayoutEffect(() => {
    navigate("/").catch(() => {});
  }, []);

  return null;
};

export default Page404;
